<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Situação vivida depois do rompimento da barragem do Fundão"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A lama de rejeitos arrasou as áreas mais baixas de Ponte do
                Gama, nas margens do Rio Gualaxo do Norte. Com a destruição da
                ponte que dava acesso ao subdistrito e das estradas, a
                comunidade ficou isolada sem ter como receber mantimentos,
                insumos, escoar a produção e manter o contato com os outros
                locais com as quais possuíam relações de cooperação, de trocas e
                comercialização. Era costume trocarem qualidades de feijão e
                milho entre os vizinhos, variando assim o plantio. Havia troca
                de animais como porcos e cavalos. Depois do rompimento, as
                trocas diminuíram cerca de 80%, já que a população e as
                plantações foram reduzidas. Na época do rompimento, até em Águas
                Claras, onde conseguiam chegar, as mercadorias ficaram escassas.
                Os caminhos possíveis, como por Fonseca, apresentavam condições
                difíceis de transitar, além de serem mais extensos.
              </p>
              <p>
                Mesmo com a reconstrução de pontes e estradas da região, Ponte
                do Gama permaneceu em isolamento social e econômico, já que as
                comunidades vizinhas foram obrigadas e se mudar para outros
                locais, a exemplo de Paracatu de Baixo, bem como moradores e
                produtores do próprio subdistrito do Gama, que em parte tiveram
                as terras invadidas pelo rejeito ou em outros casos se mudaram
                com medo de novo rompimento ou por não conseguir mais realizar
                atividades produtivas ou encontrar emprego. Parte dos produtores
                locais não teve condições de continuar plantando ou criando
                animais e precisou dispensar seus funcionários. Por outro lado,
                a evasão da população diminui a quantidade de trabalhadores
                disponíveis para prestação de serviços na roça.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_rompimento_ponte_do_gama_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de um terreno destruído pela lama, tendo sido arruinadas suas
            construções e áreas produtivas. Foto: Vistoria técnica da Cáritas,
            2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-start"
        >
          <img
            width="100%"
            src="../../assets/sobre_rompimento_ponte_do_gama_02.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Registro fotográfico da luz acesa até que o Santíssimo fosse
            resgatado da capela atingida pela lama de rejeitos. Foto: Jornal a
            Sirene, Ed. 08, novembro de 2016. Autor: Milton Sena.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Desde o rompimento da barragem, a comunidade enfrenta
                dificuldades econômicas e financeiras, sem apoio de projetos ou
                políticas públicas que prevejam a retomada da economia e das
                relações comerciais de maneira efetiva.
              </p>
              <p>
                A área onde se encontra a Capela de Nossa Senhora Aparecida, o
                centro comunitário e o campo de futebol foi assolada e somente
                parte das construções foi refeita, como a capela e o centro
                comunitário. Conforme relato dos atingidos, não houve tempo para
                tirarem as imagens do interior da capela quando foram
                surpreendidos pela chegada do rejeito em Ponte do Gama. Os
                moradores para se salvarem, correram para a parte mais alta do
                subdistrito e, quando voltaram para as áreas de baixada,
                encontraram a destruição do que ali havia. A capela foi
                encoberta pela lama até praticamente a altura do telhado e, em
                seu interior, restaram somente os bancos.
              </p>
              <p>
                Sr. José de Jair, morador de Ponte do Gama, contou que quando a
                lama atingiu a comunidade, as edificações ficaram sem energia
                elétrica. Conforme o rejeito foi baixando, o atingido foi com
                seus filhos à capela em busca do Santíssimo, que nela ficava
                exposto. Fizeram uma caminho com tábuas de madeira sobre a lama
                até o Sacrário e encontraram o Santíssimo com a luz acesa, sem
                haver energia elétrica. Pela fé católica, a presença sagrada da
                hóstia é sinalizada pela luz vermelha acesa. As hóstias que são
                distribuídas durante o ritual da comunhão são guardadas em um
                recipiente chamado ambula. O Sr. José relatou que somente quando
                retirou a âmbula do Sacrário a luz se apagou. No momento de
                tanto sofrimento e destruição, os moradores de Ponte do Gama
                mantiveram a fé e sentiram a Proteção Divina presente.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Houve ainda a interrupção de festividades tradicionais, como a
                Festa de Santa Cruz e Festa de São Vicente, permanecendo a Festa
                de Nossa Senhora Aparecida. Nesta, além da novena que antecede o
                dia 12 de outubro, da missa e da procissão, é realizado leilão e
                compartilhado um almoço. Antes da lama destruir a região, a
                procissão seguia até o campo e voltava para a capela; depois do
                desastre–crime, contorna a área à frente do templo, não podendo
                ir mais longe visto que o espaço foi reduzido. Soma-se a isso a
                perda das relações culturais com as comunidades vizinhas, com
                ênfase para a Folia de Reis de Paracatu de Baixo que passava por
                Ponte do Gama e, com o rompimento, não segue mais o trajeto
                pelas comunidades vizinhas do território. O Sr. José de Jair,
                atingido do Gama, relembra o som da Folia chegando pela noite,
                coisa que não se escuta mais. Paravam na porta das casas,
                tocavam e cantavam. Os donos da casa recebiam a bandeira,
                acolhiam nas varandas ou escutavam a Folia do terreiro,
                ofereciam café e merenda. Ele contou ainda que, quando morava em
                Paracatu, acompanhava a Folia e se recordou do palhaço fazendo
                folia à frente, enquanto brincava com os meninos.
              </p>

              <p>
                Além das estruturas comuns de lazer e da capela, a lama atingiu
                parte dos terrenos particulares produtivos de Ponte do Gama,
                gerando além dos danos econômicos, a desvalorização imobiliária
                das terras.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_rompimento_ponte_do_gama_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de um terreno destruído pela lama, tendo sido arruinadas suas
            construções e áreas produtivas. Foto: Vistoria técnica da Cáritas,
            2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-start"
        >
          <img
            width="100%"
            src="../../assets/sobre_rompimento_ponte_do_gama_04.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de parte do pavimento térreo de um casarão destruído pela
            lama. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Sítios e fazendas foram abandonados, bem como casas e
                construções de uso rural. Em outros casos, as edificações sofrem
                danos estruturais ocasionados pelo trânsito de veículos pesados
                na região, surgindo trincas e rachaduras.
              </p>
              <p>
                O desastre–crime ocasionou a destruição da caixa d’água
                principal que abastecia toda a comunidade, deixando a população
                sem água durante um período.
              </p>
              <p>
                Terrenos que se encontram em cotas mais altas não foram
                alcançados pela lama, porém, ficaram isolados por
                aproximadamente dois meses, afetando diretamente os cultivos que
                acabaram se perdendo, visto que não houve colheita e manejo
                nesse período, por muitos produtores não conseguirem chegar em
                suas terras. Os produtores de leite foram extremamente
                prejudicados, visto que não conseguiram vender a produção, nem
                mesmo os derivados, como o queijo. Também eram criadas aves para
                comercialização da carne e ovos, interrompida pela falta de
                compradores, principalmente de Paracatu e do próprio Gama. A
                criação foi diminuindo até que restou somente para o próprio
                consumo.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                As áreas de pastagens situadas nas proximidades do Rio Gualaxo
                do Norte foram devastadas pela lama de rejeitos,
                impossibilitando assim o manejo de animais. A atividade de pesca
                e o lazer como banhos de rio e cachoeira, ficaram impedidas
                depois do desastre, estando a água turva, que antes era
                clarinha, podia-se até beber. Soma-se a isso a perda de espécies
                arbóreas nas margens do Rio Gualaxo, que secaram e morreram ao
                longo dos anos. Espécies de pássaros e mesmo dos peixes também
                desapareceram, conforme o relato dos moradores.
              </p>
              <p>
                A comunidade de Ponte do Gama, como as demais situadas ao longo
                do Rio Gualaxo do Norte, convive com a insegurança e a incerteza
                quanto à contaminação da água e do solo por metais pesados;
                vivem sem saber da real condição a que foram e continuam
                expostos. Não há clareza quanto ao ar que respiram carregando a
                poeira da lama, bem como as consequências do rejeito em contato
                com a pele. O adoecimento entre a população atingida está em
                grande parte relacionado ao aumento de pressão, ansiedade,
                depressão e diabetes.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_rompimento_ponte_do_gama_04B.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Trecho do Rio Gualaxo do Norte em Ponte do Gama com água barrenta.
            Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
